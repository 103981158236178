<template lang="pug">
  .passage.pt-100vh
    //- used .fixed because ios has z-index memory issues...
    .fixed.z-10.top-0.left-0.w-full.h-screen(:style="{transform: `translateY(${dY}px)`}")
      transition(name="page")
        router-view(:key="$route.params.passage", @downArrowClick="scrollToThumbs")

    //- "thumbs" btn
    footer-links.fixed.z-10.bottom-0.left-0.px-3.text-white.transition.duration-700(:style="{coloroff: uiColor}")
      button.p-3.-mb-3.pointer-events-auto(@click="scrollToThumbs") Thumbnails

    //- index / thumbs
    .relative.z-20.min-h-screen.bg-white.scroll-snap-start.bg-vanilla(ref="thumbs")
      passages-thumbnails
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Passages',
  data () {
    return {
      dY: 0,
      afterScroll: null,
      lastUIColor: null
    }
  },
  computed: {
    ...mapState(['winH', 'uiColor']),

    thumbsVisible () {
      return this.dY < -5
    }
  },
  methods: {
    slideshowParallax () {
      const y = window.pageYOffset
      if (y <= this.winH) {
        requestAnimationFrame(() => {
          this.dY = y * -0.25
        })
      }
    },
    scrollToThumbs () {
      // window.scroll(0,500)
      this.$scrollTo(this.$refs.thumbs)
    }
  },
  watch: {
    '$route' (to, from) {
      // scroll to top when slide changes
      if (to.params.slide && to.params.slide !== from.params.slide) {
        // (prevent cancel because left/right arrows get cancelled for some reason...)
        this.$scrollTo(this.$el, { cancelable: false })
      }
    },
    thumbsVisible (vis) {
      // toggle ui to navy when thumbs visible
      if (vis) {
        this.lastUIColor = this.uiColor
        this.$store.commit('setUIColor', 'navy')
      } else if (this.lastUIColor) {
        this.$store.commit('setUIColor', this.lastUIColor)
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.slideshowParallax)
  },
  destroyed () {
    window.removeEventListener('scroll', this.slideshowParallax)
  }
}
</script>

<style>
</style>
