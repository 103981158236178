<template lang="pug">
  slices-slideshow.passage-slideshow(v-if="doc", :slices="doc.data.body", v-on="$listeners", @end="goToNextSection")
</template>

<script>
import store from '@/store'
import linkResolver from '@/plugins/prismic/link-resolver'
export default {
  name: 'PassageSlideshow',
  computed: {
    doc () {
      return this.$store.getters.passages.find(doc => doc.uid === this.$route.params.passage)
    }
    // notFound () {
    //   return this.$store.state.docs.length && !this.doc
    // }
  },
  methods: {
    goToNextSection () {
      const link = this.doc?.data?.next_section
      return link && this.$router.push(linkResolver(link))
    }
  },
  beforeRouteEnter (to, from, next) {
    store.commit('setUIColor', 'white')
    next()
  }
}
</script>

<style>
</style>
